import { Link } from 'gatsby';
import React from 'react';
import { Button, Container } from 'react-bootstrap';



function ProductDescription({ topheader, header, headerText, about, showButton, className }: { topheader?: string, header?: string, headerText?: string, about?: boolean, showButton?: boolean, className?: boolean }) {
  return (
    <div className={`service-background bg-cover ${className ? "vh-20" : "product-description-width"} d-flex align-items-end justify-content-end  min-vw-100`}>
      <Container className='mx-auto '>
        <div className='d-flex flex-column justify-content-end align-items-center mt-4 mt-lg-5'>
          <h3 className='color-lightGreen mb-4 mb-lg-5 fnt-14 fnt-md-16 fnt-lg-16 fw-700'>{topheader}</h3>
          <div className='product-header-background mb-4 mb-lg-5'>
            <div className='d-flex justify-content-center'>
              <h1 className='fw-700 fnt-24 fnt-lg-40 color-white text-center my-3  product-bottom'>{header}</h1>
            </div>
          </div>
          <p className='fnt-14 fnt-lg-16  w-lg-74  lh-lg-24 color-white text-center mb-4 mb-lg-5 fw-500'>{headerText}</p>

          {showButton && (
            <Link to="/contact-us">
              <Button className="px-md-4 mb-4 mb-lg-5 fnt-14 fnt-lg-16 btn-contact-us border-0 fnt-14 fnt-lg-16">
                Connect Now
              </Button>
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default ProductDescription;