import { Link } from 'gatsby';
import React from 'react'
import { Card } from 'react-bootstrap';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"


function OtherServicesCard({header,headertext,img,mobimg,linkto}:{header:string,headertext:string,img:string,mobimg:string,linkto:string}) {
  return (

      <Card className='common-card-effect h-100 other-services-card'>
        <Link to={linkto}>
          <div className='px-3 pt-3 pb-2 h-100'>
              <div className='fw-600 fnt-18 fnt-lg-20   lh-lg-18  color-blue mb-2'>{header}</div>
              <div className="fw-xl-400 fw-400 fnt-14 fnt-lg-16  lh-lg-26 color-black mb-2 mb-lg-3 ">{headertext}</div>
              <div className='text-end d-none d-sm-block'><LazyLoadImage effect='blur' className="" src={img} alt="cloud-img"/></div>
              <div className='text-end  d-block d-sm-none '><LazyLoadImage effect='blur' className=" "src={mobimg} alt="cloud-img"/></div>
          </div>
          </Link>
      </Card>

  )
}

export default OtherServicesCard;